<template>
<!--  我的反馈-->
<div class="container myFeedBack" id="myFeedBack">
  <div :style="{height:aType === 'android'||aType ==='ios' ? '':screenHeight+'px',overflowY:aType === 'android'||aType ==='ios' ? '':'scroll'}" class="cellSty">
    <van-cell
        v-for="list in infoList"
        :title="list.title"
        :label="list.createdTime"
        is-link
        :key="list.id"
        @click="goFeed(list)">
      <template #right-icon>
        <div style="display: flex;justify-content: center;align-items: center">
          <span v-if="!list.isStatus" style="color: #9B9B9B">待回复</span>
          <span v-else style="color: #9B9B9B">已回复</span>
          <van-icon color="#bfbebe" name="arrow" />
        </div>
      </template>
    </van-cell>

    <div class="imgNoCont" v-if="isShowNoCont">
      <div>
        <img style="width: 208px;height: 166px;" src="../../assets/image/noCont.png" alt="">
        <div style="text-align: center;margin-top: 25px;color: #959698;">暂无反馈</div>
      </div>
    </div>
  </div>
  <div style="height: 60px"></div>
  <van-button
      :class="[aType === 'android'||aType ==='ios' ? 'tabbar-wrap':'tabbar-wrapWin']"
      @click="onSubmit"
      round block
      type="info"
      native-type="submit">
    <template #icon>
      <div style="display: flex;justify-content: center;align-items: center">
        <img style="width: 20px;height: 20px;margin-right: 10px" src="../../assets/image/edit.png" alt="">
        提出反馈
      </div>
    </template>
  </van-button>
<!------------------------------------------对话框--------------------------------------------------------------->
  <van-overlay style="display: flex;justify-content: center;align-items: center" :show="show">
      <van-loading type="spinner" />
  </van-overlay>

<!--  <van-tabbar-->
<!--      :class="[aType === 'android'||aType ==='ios' ? 'tabbar-wrap':'tabbar-wrapWin']"-->
<!--      :fixed="false"-->
<!--      :border="false">-->
<!--    <div class="submitOk">-->
<!--      <van-button-->
<!--          @click="onSubmit"-->
<!--          round block-->
<!--          type="info"-->
<!--          native-type="submit">-->
<!--        <template #icon>-->
<!--          <div style="display: flex;justify-content: center;align-items: center">-->
<!--            <img style="width: 20px;height: 20px;margin-right: 10px" src="../../assets/image/edit.png" alt="">-->
<!--            提出反馈-->
<!--          </div>-->
<!--        </template>-->
<!--      </van-button>-->
<!--    </div>-->
<!--  </van-tabbar>-->
</div>
</template>

<script>
import {getMyFeedBack} from '../../service/helpCenter'
import {getAdmins} from "@/utils/adminDate";
import {setById} from "@/service/base";

export default {
  name: "myFeedBack",
  data(){
    return{
      show:false,
      infoList:[],
      aType:'',
      screenHeight:0,
      isShowNoCont:false,
    }
  },
  created() {
    this.$nextTick(() => {
      var scrollHeight = document.getElementById("myFeedBack").scrollHeight;
      this.screenHeight = scrollHeight-60
    });
    this.getList()
    //  获取本地存储的系统
    this.aType = localStorage.getItem('helpCenterType')
  },
  methods:{
    async getById(){
      //像接口头部传用户信息
      let arrays = await getAdmins(this.$route.query.memberId,this.$route.query.channel)
      this.channel = arrays.os
      this.version = arrays.version
      this.ip = arrays.ip
      await setById(arrays)
    },
    async getList() {
      await this.getById()
      this.show = true
      const response = await getMyFeedBack(this.$route.query.memberId)
      if(response.code === 0){
        this.infoList = response.data;
        if(this.infoList.length){
          this.isShowNoCont = false;
        }else {
          this.isShowNoCont = true;
        }
      }else {
        this.$message.warning(response.message)
      }
      this.show = false
    },
    goFeed(row){
      let str = JSON.stringify(row);
      this.$router.push({ path: "/feedBackDetails", query: { str } });
    },
    onSubmit(){
      this.$router.push({path:'/feedBack',query:{memberId:this.$route.query.memberId}})
    },
  }
}
</script>

<style scoped>
.myFeedBack{
  width: 100%;
}
.cellSty{
  scrollbar-width: none;
  -ms-overflow-style: none; /* IE 10+ */
}
.cellSty::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.submitOk{
  width: 90%;
  margin: 0 auto;
}
.tabbar-wrap{
  position: fixed;
  bottom: 0;
  height: 50px;
  line-height: 50px;
}
.tabbar-wrapWin{
  position: absolute;
  bottom: 0;
  height: 50px;
  line-height: 50px;
}
.imgNoCont{
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 40px;
}
</style>
